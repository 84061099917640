import { getClasseurFiche } from "../../API/v2/getClasseurFiche";

/**
 * Interface for the request data.
 */
interface REQUEST_T {
    /**
     * Number of financial service providers supported in setting up strategies for serving rural areas.
     */
    "Nombre de prestataires de services financiers soutenus dans la mise en place de stratégies de desserte, et de services et produits financiers dans les zones rurales": number,
    /**
     * Region identifier.
     */
    Région: number
}

/**
 * Retrieves the number of financial service providers supported in setting up strategies for serving rural areas.
 * 
 * @returns A promise that resolves with an array of objects containing the number of financial service providers supported and the region identifier.
 * 
 * @example
 * nombrePrestataireDeServiceFinancierSoutenueDansLaMiseEnPlaceDeStrategie()
 *   .then((data) => {
 *     console.log(data);
 *     // Output: [
 *     //   {
 *     //     "Nombre de prestataires de services financiers soutenus dans la mise en place de stratégies de desserte, et de services et produits financiers dans les zones rurales": 10,
 *     //     "Région": 1
 *     //   },
 *     //   {
 *     //     "Nombre de prestataires de services financiers soutenus dans la mise en place de stratégies de desserte, et de services et produits financiers dans les zones rurales": 20,
 *     //     "Région": 2
 *     //   }
 *     // ]
 *   })
 *   .catch((error) => {
 *     console.error(error);
 *   });
 */
export const nombrePrestataireDeServiceFinancierSoutenueDansLaMiseEnPlaceDeStrategie = (): Promise<REQUEST_T[]> => {
    return new Promise(
        (resolve, reject) => {
            getClasseurFiche(7, 21).then(
                (data: REQUEST_T[]) => {
                    resolve(data);
                }
            ).catch(
                data => reject(data)
            )
        }
    )
}