import { getClasseurFiche } from "../../API/v2/getClasseurFiche";

interface REQUEST_T {
    "Nombre d'hommes ayant accès à des services financiers (crédit) dans les zones rurales": number,
    "Nombre de femmes ayant accès à des services financiers (crédit) dans les zones rurales": number,
    "Nombre de femmes ayant accès à des services financiers (épargne) dans les zones rurales": number,
    "Nombre de personnes en situation de handicap ayant accès à des services financiers (crédit)dans les zones rurales": number,
    Région: string
}

/**
 * Retrieves the number of people with access to financial services in rural areas.
 * 
 * @returns A promise that resolves to an array of objects containing the number of people with access to financial services in rural areas, 
 *          broken down by demographic categories (men, women, people with disabilities) and region.
 * 
 * @example
 * personneAccessServicesFinanciers().then((data) => {
 *   console.log(data);
 *   // Output:
 *   // [
 *   //   {
 *   //     "Nombre d'hommes ayant accès à des services financiers (crédit) dans les zones rurales": 100,
 *   //     "Nombre de femmes ayant accès à des services financiers (crédit) dans les zones rurales": 50,
 *   //     "Nombre de femmes ayant accès à des services financiers (épargne) dans les zones rurales": 200,
 *   //     "Nombre de personnes en situation de handicap ayant accès à des services financiers (crédit)dans les zones rurales": 20,
 *   //     "Région": "Région 1"
 *   //   },
 *   //   {
 *   //     "Nombre d'hommes ayant accès à des services financiers (crédit) dans les zones rurales": 150,
 *   //     "Nombre de femmes ayant accès à des services financiers (crédit) dans les zones rurales": 75,
 *   //     "Nombre de femmes ayant accès à des services financiers (épargne) dans les zones rurales": 250,
 *   //     "Nombre de personnes en situation de handicap ayant accès à des services financiers (crédit)dans les zones rurales": 30,
 *   //     "Région": "Région 2"
 *   //   }
 *   // ]
 * });
 */
export const personneAccessServicesFinanciers = (): Promise<REQUEST_T[]> => {
    // cette api recupere le Nombre de Personnes ayant accès à des services financiers dans les zones rurales
    return new Promise(
        (resolve, reject) => {
            getClasseurFiche(7, 20).then(
                (data: REQUEST_T[]) => {
                    resolve(data);
                }
            ).catch(
                data => reject(data)
            )
        }
    )
}