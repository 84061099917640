import { getClasseurFiche } from "../../API/v2/getClasseurFiche";

interface REQUEST_T {
    "Age moyen des membres selin l'ordre de renseignement des membres ": number,
    "Domaine du groupement": string,
    "Montant de l'investissement FIER 2": number,
    "Montant du financement par facilitation": number,
    "Nom du groupement": string,
    "Nombre d'emploi crée": number,
    "Nombre d'hommes membres du groupement": number,
    "Nombre de bénéficiaire du groupement": number,
    "Nombre de femmes membre du groupement": number,
    "Nombre de personnes en situation de handicap membre du groupement": number,
    "Region ": string,
    "Type de groupement": string
}

/**
 * This function retrieves financial data related to the "FIER 2" program for a specific region and type of groupement.
 *
 * @returns A Promise that resolves to an array of objects, each containing financial data for a groupement.
 * The object properties are:
 *  - "Age moyen des membres selin l'ordre de renseignement des membres " (average age of members based on the order of registration)
 *  - "Domaine du groupement" (groupement domain)
 *  - "Montant de l'investissement FIER 2" (amount of investment from FIER 2)
 *  - "Montant du financement par facilitation" (amount of financing through facilitation)
 *  - "Nom du groupement" (name of groupement)
 *  - "Nombre d'emploi crée" (number of jobs created)
 *  - "Nombre d'hommes membres du groupement" (number of male members)
 *  - "Nombre de bénéficiaire du groupement" (number of beneficiaries of the groupement)
 *  - "Nombre de femmes membre du groupement" (number of female members)
 *  - "Nombre de personnes en situation de handicap membre du groupement" (number of members with disabilities)
 *  - "Region " (region)
 *  - "Type de groupement" (type of groupement)
 *
 * @example
 * financementFier2AGR().then(data => {
 *   console.log(data);
 * }).catch(error => {
 *   console.error(error);
 * });
 */
export const financementFier2AGR = (): Promise<REQUEST_T[]> => {
    return new Promise(
        (resolve, reject) => {
            getClasseurFiche(6, 17).then(
                (data: REQUEST_T[]) => {
                    resolve(data);
                }
            ).catch(
                data => reject(data)
            )
        }
    )
}