import { faInfoCircle, faPaperPlane, faRotateLeft, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Card, CardCover, Stack, Typography } from "@mui/joy"
import { COLOR, IMAGE } from "../../constants/constants"
import { Divider } from "@mui/material"
import InputModel from "../InputModel/InputModel"
import { useEffect, useState } from "react"

const FormModel = ({ formNumber, setformNumber }) => {
    const [formData, setFormData] = useState({});

    const [isSending, setisSending] = useState(false);

    const [formTitle, setformTitle] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        setisSending(true);
    };

    useEffect(
        () => {
            switch (formNumber) {
                case 1:
                    setformTitle("Formulaire de soumission de dossier");
                    break;
                case 2:
                    setformTitle("Formulaire de dépot de pleinte");
                    break;

            }
        },
        [formNumber]
    );

    return (
        formNumber > 0
            ? (
                <form
                    style={{
                        width: "100vw",
                        height: "100vh",
                        position: "fixed",
                        display: "flex",
                        top: 0,
                        left: 0,
                        background: "rgba(29, 28, 28, 0.706)",
                        backdropFilter: "blur(10px)",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 9999
                    }}

                    onSubmit={handleSubmit}
                >
                    <Stack
                        bgcolor={COLOR.lightGrey}
                        p={1}
                        borderRadius={15}
                        gap={2}
                        width={"calc(100% - 50px)"}
                        maxWidth={500}
                        minWidth={300}
                    >
                        <Stack
                            width={30}
                            height={30}
                            bgcolor={COLOR.red}
                            justifyContent={"center"}
                            borderRadius={10}
                            color={COLOR.white}
                            alignSelf={"flex-end"}
                            onClick={() => { setformNumber(0) }}
                            zIndex={1}
                            sx={{
                                cursor: "pointer"
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Stack>

                        <Stack
                            direction={"row"}
                            gap={2}
                            mt={-5}
                        >
                            <Card
                                sx={{
                                    width: 50,
                                    minWidth: 50,
                                    height: 50,
                                    borderRadius: 0
                                }}
                            >
                                <CardCover>
                                    <img src={IMAGE.defaultImage} />
                                </CardCover>
                            </Card>
                            <Box
                                alignSelf={"flex-end"}
                            >
                                <Box>
                                    <Typography
                                        level="h4"
                                    >
                                        {formTitle || "Formulaire"}
                                    </Typography>
                                </Box>

                                <Typography gap={1} level="body-sm">
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                    <Typography> Les champs dotés d'un * sont obligatoirs</Typography>
                                </Typography>
                            </Box>
                        </Stack>

                        <Divider />

                        <InputModel handleChange={handleChange} name={"nom"} label={"Nom"} />
                        <InputModel handleChange={handleChange} isRequired name={"prenom"} label={"Prenom"} />
                        <InputModel handleChange={handleChange} isRequired name={"tel"} label={"Tel"} />
                        <InputModel handleChange={handleChange} name={"email"} label={"Email"} type={"email"} />
                        <InputModel handleChange={handleChange} name={"adresse"} label={"Adresse"} />

                        <Stack
                            direction={"row"}
                            gap={1}
                        >
                            <Button
                                sx={{
                                    bgcolor: COLOR.darkGrey,
                                    gap: 1,
                                    ":hover": {
                                        bgcolor: COLOR.falseBlack
                                    }
                                }}
                                type="reset"
                            >
                                Reset
                                <FontAwesomeIcon icon={faRotateLeft} />
                            </Button>
                            <Button
                                sx={{
                                    width: "100%",
                                    gap: 1,
                                    ":hover": {
                                        bgcolor: COLOR.darKblue
                                    }
                                }}
                                type="submit"
                                loading={isSending}
                            >
                                Soumettre
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            )
            : null
    )
}

export default FormModel