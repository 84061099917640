import { getClasseurFiche } from "../../API/v2/getClasseurFiche";

/**
 * Interface representing the request data
 */
interface REQUEST_T {
    "Nombre d'installation de commercialistaion construite ": number,
    "Nombre d'installation de commercialistaion mis en \u00e9tat ": number,
    "Nombre d'installation de transformation et de stockage  construite": number,
    "Nombre d'installation de transformation et de stockage  mis en \u00e9tat ": number,
    "Régions": string,
};

/**
 * Retrieves the number of commercialization, transformation, and storage installations built or put into service.
 *
 * @returns A promise resolving to an array of REQUEST_T objects
 * @example
 * nombreInstallationCommercialisationRemisEnEtat().then((data) => {
 *   console.log(data); // [
 *     {
 *       "Nombre d'installation de commercialistaion construite ": 10,
 *       "Nombre d'installation de commercialistaion mis en état ": 5,
 *       "Nombre d'installation de transformation et de stockage construite": 8,
 *       "Nombre d'installation de transformation et de stockage mis en état ": 3,
 *       "Régions": "Region 1"
 *     },
 *     {
 *       "Nombre d'installation de commercialistaion construite ": 20,
 *       "Nombre d'installation de commercialistaion mis en état ": 10,
 *       "Nombre d'installation de transformation et de stockage construite": 15,
 *       "Nombre d'installation de transformation et de stockage mis en état ": 8,
 *       "Régions": "Region 2"
 *     }
 *   ]
 * });
 */
export const nombreInstallationCommercialisationRemisEnEtat = (): Promise<REQUEST_T[]> => {
    // This API retrieves the number of commercialization, transformation, and storage installations built or put into service
    return new Promise(
        (resolve, reject) => {
            getClasseurFiche(7, 19).then(
                (data: REQUEST_T[]) => {
                    console.log(data)
                    resolve(data);
                }
            ).catch(
                data => reject(data)
            )
        }
    )
}