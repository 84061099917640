import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { Box, Divider, Stack } from "@mui/material";
import NavBar from "./components/NavBar/NavBar";
import Router from "./components/Router";
import { COLOR } from "./constants/constants";
import Footer from "./components/Footer/Footer";
import "./res/others/leaflet.css";

function App() {
  return (
    <BrowserRouter>
      <Box
        bgcolor={COLOR.blue}
        height={200}
      // borderTop={`5px solid ${COLOR.orange}`}
      >
        <Stack
          direction={"row"}
          width={"100%"}
        >
          <Box
            width={"100%"}
            height={5}
            bgcolor={COLOR.green}
            ></Box>
          <Box
            width={"100%"}
            height={5}
            bgcolor={COLOR.orange}
            ></Box>
          <Box
            width={"100%"}
            height={5}
            bgcolor={COLOR.red}
          ></Box>
        </Stack>
        <NavBar />
        <Divider
          color={COLOR.lightblue}
          sx={{ marginBottom: 5 }}
        />
        <Box
          paddingX={"5%"}
          overflow={"hidden"}
        >
          <Router />
        </Box>
        <Footer />
      </Box>

    </BrowserRouter>
  )
}

export default App