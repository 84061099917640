import { getClasseurFiche } from "../../API/v2/getClasseurFiche";

/**
 * Interface for a beneficiary object.
 */
interface REQUEST_T {
    /**
     * The age of the beneficiary.
     */
    Age: number;

    /**
     * The domains of the beneficiary.
     */
    Domaines: string;

    /**
     * The genre of the beneficiary.
     */
    Genre: string;

    /**
     * The name of the beneficiary.
     */
    Nom: string;

    /**
     * The first name of the beneficiary.
     */
    Prénom: string;

    /**
     * The region of the beneficiary.
     */
    Région: string;

    /**
     * Whether the beneficiary has financing for aggregate infrastructure (AGR or MER).
     */
    "Si financement Infrastructures agregatives AGR OU MER": string;

    /**
     * The type of course the beneficiary is taking.
     */
    "Type de parcours": string;
}

/**
 * Retrieves the list of beneficiaries for a project.
 *
 * @returns {Promise<REQUEST_T[]>} A promise that resolves to an array of beneficiary objects.
 *
 * @example
 * identificationBeneficiaires().then((beneficiaries) => {
 *   console.log(beneficiaries);
 * }).catch((error) => {
 *   console.error(error);
 * });
 */

export const identificationBeneficiaires = (): Promise<REQUEST_T[]> => {
    // cette api recupere les beneficiaires du projet
    return new Promise(
        (resolve, reject) => {
            getClasseurFiche(6, 16).then(
                (data: REQUEST_T[]) => {
                    resolve(data);
                }
            ).catch(
                data => reject(data)
            )
        }
    )
}