import { NOMBRE_D_EMPLOIE_CREE_T } from "../../../constants/objectTye";
import { getClasseurFiche } from "../../API/v2/getClasseurFiche";

export const nombreDEmploieCree = ():
    Promise<NOMBRE_D_EMPLOIE_CREE_T[]> => {
    // cette api recupere le nombre D'emploie cree
    return new Promise(
        (resolve, reject) => {
            getClasseurFiche(6, 25).then(
                (data: NOMBRE_D_EMPLOIE_CREE_T[]) => {
                    resolve(data);
                }
            ).catch(
                data => reject(data)
            )
        }
    )
}