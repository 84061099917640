import { Stack } from "@mui/joy"
import LinkButton from "../../components/LinkButton.tsx/LinkButton"
import { COLOR } from "../../constants/constants"
import TableModel from "../../components/TableModel/TableModel"
import { useEffect, useState } from "react"
import { nombreInstallationCommercialisationRemisEnEtat } from "../../functions/fiches/suiviIndicateurs/nombreInstallationCommercialisationRemisEnEtat"
import { entrepriseSignalantUneHausseDeBenefices } from "../../functions/fiches/suiviIndicateurs/entrepriseSignalantUneHausseDeBenefices"
import { getClasseurFiche } from "../../functions/API/v2/getClasseurFiche"
import { nombreDEmploieCree } from "../../functions/fiches/listeBeneficiaires/nombreDEmploieCree"

const StatistiqueZone = () => {
    const [dataTableMenageAmeliore, setdataTableMenageAmeliore] = useState(null);
    const [commercialisationRemisEnEtat, setcommercialisationRemisEnEtat] = useState(null);
    const [dataentrepriseSignalantUneHausseDeBenefices, setdataentrepriseSignalantUneHausseDeBenefices] = useState(null);
    const [datanombreEmploieCree, setdatanombreEmploieCree] = useState(null)

    useEffect(() => {
        getClasseurFiche(4, 13).then(data => {
            const dataTmp = [];
            for (const iterator of data) {
                dataTmp.push(
                    [
                        iterator["Zones d'intervention"],
                        iterator["Année"],
                        iterator["Nombre de ménages déclarant une amélioration de l’accès physique aux marchés et aux installations de transformation et de stockage"],
                    ]
                )
            }

            setdataTableMenageAmeliore(dataTmp)
        });

        nombreInstallationCommercialisationRemisEnEtat().then(
            (data) => {
                const dataTmp = [];

                data.map(value => {
                    dataTmp.push(
                        [
                            value["Nombre d'installation de commercialistaion construite "],
                            value["Nombre d'installation de transformation et de stockage  construite"],
                            value["Nombre d'installation de transformation et de stockage  mis en \u00e9tat "],
                            value["Nombre d'installation de commercialistaion mis en \u00e9tat "],
                            value.Régions,
                        ]
                    )
                });

                setcommercialisationRemisEnEtat(dataTmp);
            }
        );

        entrepriseSignalantUneHausseDeBenefices().then(
            (data) => {
                const dataTmp = [];

                data.map(iterator => {
                    dataTmp.push([
                        iterator["Nombre d'entreprises rurales appuyées signalant une hausse de leurs bénéfices"],
                        iterator["Nombre de Personnes dont le revenu a augmenté"],
                        iterator["Nombre de personnes dont la résilience s’est renforcée "],
                        iterator.Région
                    ]);
                });

                setdataentrepriseSignalantUneHausseDeBenefices(dataTmp);
            }
        );

        nombreDEmploieCree().then(
            (data) => {
                const dataTmp = [];

                data.map(value => {
                    dataTmp.push(
                        [
                            value["Nombre total d'emploi crée par le projet"].toLocaleString(),
                            value["Emploi AGR"].toLocaleString(),
                            value["Emploi MER "].toLocaleString(),
                            value["Nombre d'homme employé"].toLocaleString(),
                            value["Nombre de femme employée"].toLocaleString(),
                            value.Région,
                        ]
                    )
                });

                setdatanombreEmploieCree(dataTmp);
            }
        );
    }, [])

    return (
        <Stack
            alignItems={"center"}
            bgcolor={COLOR.white}
            p={2}
            gap={3}
        >
            <LinkButton
                content={"Quelques statitique"}
                style={{
                    // background : COLOR.falseBlack,
                    border: `5px solid ${COLOR.blue}`,
                    padding: 3
                }}
            />

            <Stack
                direction={"row"}
                // flexWrap={"wrap"}
                gap={2}
                sx={{
                    overflowX: "scroll",
                    p: 2,
                    "::-webkit-scrollbar": {
                        display: "initial"
                    }
                }}
                width={"100%"}
                maxWidth={"100%"}
            >
                <TableModel
                    header={["Total d'emploi crée", "Emploi AGR", "Emploi MER", "Homme employé", "Femme employée", "Région"]}
                    data={datanombreEmploieCree}
                    tableTilte={"Nombre d'emploie créé par le projet FIER 2"}
                    maxWidth={400}
                    height={window.innerWidth > 600 && "initial"}
                />

                <TableModel
                    header={["Region", "Année", "Nombre de ménage"]}
                    data={dataTableMenageAmeliore}
                    tableTilte={"Nombre de ménages déclarant une amélioration de l’accès physique aux marchés et aux installations de transformation et de stockage"}
                    maxWidth={400}
                    height={window.innerWidth > 600 && "initial"}
                />

                <TableModel
                    header={[
                        "Commercialistaion construite",
                        "Transformation et stockage construite",
                        "Transformation et stockage mis en état",
                        "Commercialistaion mis en état",
                        "Régions",
                    ]}
                    data={commercialisationRemisEnEtat}
                    tableTilte={"Nombre d'installations de commercialisation, transformation et stockage construites ou remises en état"}
                    maxWidth={400}
                    height={window.innerWidth > 600 && "initial"}
                />

                <TableModel
                    header={[
                        "Entreprises rurales signalant une hausse de leurs bénéfices",
                        "Personnes dont le revenu a augmenté",
                        "Personnes dont la résilience s’est renforcée",
                        "Régions",
                    ]}
                    data={dataentrepriseSignalantUneHausseDeBenefices}
                    tableTilte={"Entreprises rurales appuyées signalant une hausse de leurs bénéfices"}
                    maxWidth={400}
                    height={window.innerWidth > 600 && "initial"}
                />
            </Stack>

        </Stack>
    )
}

export default StatistiqueZone