import { faComment, faCommentSlash, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, ModalClose, Sheet, Stack, Typography } from '@mui/joy'
import { COLOR } from '../../constants/constants'

const DemandeOptionZone = ({ isDemandeOptionZone, setisisDemandeOptionZone, setformNumber }) => {

  const OptionItem = ({ title, icone, hoverTitle, onclick }) => {
    return (
      <Stack
        alignItems={"center"}
        width={150}
        height={150}
        bgcolor={COLOR.lightblue}
        borderRadius={15}
        overflow={"hidden"}
        title={hoverTitle}
        onClick={onclick}

        sx={{
          transition: `all 0.09s`,
          cursor: `pointer`,
          ":hover": {
            transform: `scale(1.05)`
          }
        }}
      >
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          height={"100%"}
          bgcolor={COLOR.blue}
          fontSize={70}
        >
          <FontAwesomeIcon
            icon={icone || faFileDownload}
            color={COLOR.white}
          />
        </Stack>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          height={50}
        >
          <Typography
            fontWeight={700}
          >
            {title || `title`}
          </Typography>
        </Stack>
      </Stack>
    )
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isDemandeOptionZone}
      onClose={() => setisisDemandeOptionZone(false)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 500,
          borderRadius: 20,
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
          pr={5}
        >
          Choisissez une option
        </Typography>

        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-evenly"}
          gap={2}
        >
          <OptionItem
            title={"Depot de dossier"}
            hoverTitle={"Cliquez ici pour effectuer une demande d'adhésion au programme."}
            onclick={() => {
              setformNumber(1);
              setisisDemandeOptionZone(false);
            }}
          />

          <OptionItem
            icone={faComment}
            title={"Depot de plainte"}
            hoverTitle={"Cliquez ici pour effectuer une de demande de plainte."}
            onclick={() => {
              setformNumber(2);
              setisisDemandeOptionZone(false);
            }}
          />

        </Stack>

      </Sheet>
    </Modal>
  )
}

export default DemandeOptionZone