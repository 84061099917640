import { Box, Stack, Typography } from "@mui/joy";
import { COLOR } from "../../constants/constants";
import LienUtilItem from "./LienUtilItem";
import { useEffect, useState } from "react";
import { getAllClasseurs } from "../../functions/API/v2/getAllClasseurs";

const DonneeZone = () => {
    const [ficheListe, setficheListe] = useState([]);

    useEffect(() => {
        const ficheListeTmp = [];

        getAllClasseurs().then(
            data => {
                data.map(value=>{
                    value["fiches classeur"].map((currentFiche)=>{
                        ficheListeTmp.push({
                            libelle: currentFiche["Libelle feuille"],
                            classeurId: value.Id,
                            ficheId: currentFiche.Id
                        });
                    })
                })

                setficheListe(ficheListeTmp);
            }
        )
    }, [])

    return (
        <Stack
            width={150}
            gap={2}
        >
            <Typography level="h3" textColor={COLOR.white}>
                Liste divers
                <Box
                    width={100}
                    height={8}
                    borderRadius={10}
                    bgcolor={COLOR.white}
                ></Box>
            </Typography>

            <Box
                maxHeight={360}
                overflow={"scroll"}
            >
                {
                    ficheListe.map((value, index) => (
                        <LienUtilItem
                            title={value?.libelle}
                            href={null}
                            key={index}
                            fontSize={10}
                            fontWeight={"initial"}
                            bgcolor={"none"}
                        />
                    ))
                }
            </Box>
        </Stack>
    )
}

export default DonneeZone