import { IDENTIFICATION_INFRACSTRUCTURES_CONSTRUITES_OU_REMIS_EN_ETAT_PAR_FIER2_T } from "../../../constants/objectTye";
import { getClasseurFiche } from "../../API/v2/getClasseurFiche";

export const identificationInfracstructuresConstruitesOuRemisEnEtatParFier2 = ():
    Promise<IDENTIFICATION_INFRACSTRUCTURES_CONSTRUITES_OU_REMIS_EN_ETAT_PAR_FIER2_T[]> => {
    // cette api recupere les infracstructures Construites Ou Remis En Etat Par Fier2
    return new Promise(
        (resolve, reject) => {
            getClasseurFiche(6, 27).then(
                (data: IDENTIFICATION_INFRACSTRUCTURES_CONSTRUITES_OU_REMIS_EN_ETAT_PAR_FIER2_T[]) => {
                    resolve(data);
                }
            ).catch(
                data => reject(data)
            )
        }
    )
}